import type {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from '@angular/fire/firestore';
import { ArticleModel, WithId } from '@padspin/models';

export const articleDataConverter: FirestoreDataConverter<
  WithId<ArticleModel>
> = {
  toFirestore: (o: WithFieldValue<ArticleModel>): DocumentData => {
    const wfvam: WithFieldValue<ArticleModel> = {
      author: o.author,
      title: o.title,
      description: o.description,
      content: o.content,
      created_at: o.created_at,
      published_at: o.published_at || null,
      draft_author: o.draft_author,
      draft_title: o.draft_title,
      draft_description: o.draft_description,
      draft_content: o.draft_content,
    };
    return wfvam;
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): WithId<ArticleModel> => {
    const d = snapshot.data(options);
    const am: WithId<ArticleModel> = {
      id: snapshot.id,
      author: d['author'],
      title: d['title'],
      description: d['description'],
      content: d['content'],
      created_at: d['created_at'],
      published_at: d['published_at'] || null,
      draft_author: d['draft_author'],
      draft_title: d['draft_title'],
      draft_description: d['draft_description'],
      draft_content: d['draft_content'],
    } as const;
    return am;
  },
};
