import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import {
  MessageAutomationModel,
  LeadsModelID,
  messageAutomationName,
  leadsModelName,
  MessageTemplate,
  message_template_name,
  MessageTemplateID,
} from '@padspin/models';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
  deleteDoc,
  collectionChanges,
  orderBy,
} from '@angular/fire/firestore';
import { addDays, setHours, startOfTomorrow } from 'date-fns';
import { Listings2Service } from './listings-2.service';
import { map } from 'rxjs/operators';
import { collection as collection$, doc as doc$ } from 'rxfire/firestore';
import { parsePhoneNumber } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root',
})
export class MessageAutomationService {
  constructor(private readonly listings: Listings2Service) {}

  queueLead = async (
    lead: LeadsModelID & Required<Pick<LeadsModelID, 'name' | 'phone'>>
  ): Promise<void> => {
    console.log(`Finding listing ${lead.listing_id}`);
    const listing = await firstValueFrom(
      this.listings.getListing$(lead.listing_id)
    );
    const template_now = await firstValueFrom(
      this.getTemplate$('lead_msg_immediate')
    );
    if (!template_now.sms_body) {
      throw new Error('sms_body missing from lead_msg_immediate template');
    }
    const now: MessageAutomationModel = {
      send_at: serverTimestamp(),
      send_to: lead.phone,
      media_url: listing.image_jpeg || null,
      data: {
        beds: listing.bedrooms,
        neighborhood: listing.neighborhood || listing.city,
        price: listing.rent_amount,
        name: lead.name,
        listing_url: `https://padspin.com/l/${listing.slug || listing.id}`,
      },
      template: template_now.sms_body,
    };
    const template_tomorrow = await firstValueFrom(
      this.getTemplate$('lead_msg_day_2')
    );
    if (!template_tomorrow.sms_body) {
      console.log(template_tomorrow.sms_body);
      throw new Error('sms_body missing from lead_msg_day_2 template');
    }
    const tomorrow: MessageAutomationModel = {
      send_at: Timestamp.fromDate(setHours(startOfTomorrow(), 10)),
      send_to: lead.phone,
      media_url: listing.image_urls_1200[0],
      data: {
        beds: listing.bedrooms,
        neighborhood: listing.neighborhood || listing.city,
        price: listing.rent_amount,
        name: lead.name,
        listing_url: `https://padspin.com/l/${listing.slug || listing.id}`,
      },
      template: template_tomorrow.sms_body,
    };
    const template_next_day = await firstValueFrom(
      this.getTemplate$('lead_msg_day_3')
    );
    if (!template_next_day.sms_body) {
      console.log(template_next_day.sms_body);
      throw new Error('sms_body missing from lead_msg_day_3 template');
    }
    const next_day: MessageAutomationModel = {
      send_at: Timestamp.fromDate(addDays(setHours(startOfTomorrow(), 11), 2)),
      send_to: lead.phone,
      media_url: listing.image_urls_1200[0],
      data: {
        beds: listing.bedrooms,
        neighborhood: listing.neighborhood || listing.city,
        price: listing.rent_amount,
        name: lead.name,
        listing_url: `https://padspin.com/l/${listing.slug || listing.id}`,
      },
      template: template_next_day.sms_body,
    };
    await addDoc(collection(getFirestore(), messageAutomationName), now);
    await addDoc(collection(getFirestore(), messageAutomationName), tomorrow);
    await addDoc(collection(getFirestore(), messageAutomationName), next_day);
    await setDoc(
      doc(collection(getFirestore(), leadsModelName), lead.id),
      { automation_started_at: serverTimestamp() },
      { merge: true }
    );
  };

  /** Dequeue the most recent lead by phone number */
  dequeueLeadByPhone = async (phone: string): Promise<number> => {
    const phoneE164 = parsePhoneNumber(phone).number as string;
    const queuedMessages = await getDocs(
      query(
        collection(getFirestore(), messageAutomationName),
        where('phone', '==', phoneE164)
      )
    );
    let deletedCount = 0;
    for (const message of queuedMessages.docs) {
      await deleteDoc(message.ref);
      deletedCount++;
    }
    const leadSnaps = await getDocs(
      query(
        collection(getFirestore(), leadsModelName),
        where('phone', '==', phoneE164),
        orderBy('created_at', 'desc')
      )
    );
    if (leadSnaps.docs && leadSnaps.docs[0]) {
      const leadSnap = leadSnaps.docs[0];
      await setDoc(
        leadSnap.ref,
        { automation_started_at: null },
        { merge: true }
      );
    }
    return deletedCount;
  };

  dequeueLead = async (lead: LeadsModelID): Promise<void> => {
    const queuedMessages = await getDocs(
      query(
        collection(getFirestore(), messageAutomationName),
        where('send_to', '==', lead.phone)
      )
    );
    for (const message of queuedMessages.docs) {
      await deleteDoc(message.ref);
    }
    await setDoc(
      doc(collection(getFirestore(), leadsModelName), lead.id),
      { automation_started_at: null },
      { merge: true }
    );
  };

  /** Convert simple html (from the p-editor) to text with newline characters semi-reliably */
  convertSimpleHTMLToText = (str: string | null): string => {
    if (!str) {
      return '';
    }
    str = str
      .replace(/<style[^>]*>.*<\/style>/gm, '')
      // Remove script tags and content
      .replace(/<script[^>]*>.*<\/script>/gm, '')
      // new lines
      .replace(/<\/p>/gi, '\n')
      .replace(/<\/h1>/gi, '\n')
      .replace(/<\/h2>/gi, '\n')
      .replace(/<\/li><\/ol>/gi, '\n')
      .replace(/<\/li><\/ul>/gi, '\n')
      .replace(/<\/li>/gi, '\n')
      .replace(/<\/ul>/gi, '\n')
      .replace(/<\/ol>/gi, '\n')
      .replace(/<br>/gi, '\n')
      .replace(/<[^>]+>/gm, '')
      // Remove leading spaces and repeated CR/LF
      .replace(/([\r\n]+ +)+/gm, '');
    return str;
  };

  getTemplatesChanges$ = (): Observable<MessageTemplateID[]> => {
    const firestore = getFirestore();
    return collectionChanges(collection(firestore, message_template_name)).pipe(
      map((snapshots) =>
        snapshots.map((snap) => {
          if (snap.type === 'removed') {
            return;
          }
          return { id: snap.doc.id, ...(snap.doc.data() as MessageTemplate) };
        })
      ),
      map(
        (templates) =>
          templates.filter((template) => !!template) as MessageTemplateID[]
      )
    );
  };

  getTemplates$ = (): Observable<MessageTemplateID[]> =>
    collection$(collection(getFirestore(), message_template_name)).pipe(
      map((snaps) =>
        snaps.map((snap) => ({
          ...(snap.data() as MessageTemplate),
          id: snap.id,
        }))
      )
    );

  getTemplate$ = (template_id: string): Observable<MessageTemplateID> => {
    return doc$(
      doc(collection(getFirestore(), message_template_name), template_id)
    ).pipe(
      map((snap) => ({ ...(snap.data() as MessageTemplate), id: snap.id }))
    );
  };

  updateTemplate = async (
    template_id: string,
    template: Partial<MessageTemplate>
  ): Promise<void> => {
    const firestore = getFirestore();
    await setDoc(
      doc(collection(firestore, message_template_name), template_id),
      template,
      { merge: true }
    );
  };
}
