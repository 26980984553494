import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
  serverTimestamp,
} from '@angular/fire/firestore';
import { SocialMediaAccountModel } from '@padspin/models';

export const socialMediaAccountsConverter: FirestoreDataConverter<SocialMediaAccountModel> =
  {
    toFirestore: function (
      o: WithFieldValue<SocialMediaAccountModel>
    ): DocumentData {
      return {
        handle: o.handle,
        createdAt:
          o.createdAt instanceof Date
            ? Timestamp.fromDate(o.createdAt)
            : serverTimestamp(),
        lat: o.lat,
        lng: o.lng,
        type: o.type,
      };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options?: SnapshotOptions
    ): SocialMediaAccountModel {
      const data = snapshot.data(options);
      return {
        handle: data['handle'],
        createdAt:
          data['createdAt'] instanceof Timestamp
            ? data['createdAt'].toDate()
            : new Date(data['createdAt']),
        lat: data['lat'],
        lng: data['lng'],
        type: data['type'],
      };
    },
  };
