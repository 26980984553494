import { Injectable } from '@angular/core';
import {
  ArticleModel,
  articleCollectionName,
  FilterModel,
  filterName,
  MessageAutomationModel,
  messageAutomationName,
  MessageTemplate,
  message_template_name,
  LeadsModel,
  leadsModelName,
  ListingsAdminModel,
  listingsAdminName,
  NotificationPreferences,
  notificationPreferencesName,
  RecordingsModel,
  recordingsModelName,
  UserNote,
  userNoteName,
  Viewing,
  viewingName,
  conversation_model_name,
  conversation_messages_model_name,
  UserProfile,
  profile_model_name,
  TenantCriteriaModel,
  tenantCriteriaName,
  MessageSMSModel,
  messageSMSName,
} from '@padspin/models';
import { ListingsModel } from '@padspin/models';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getFirestore,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';

/**
 * Database typings for use in the frontend Angular codebase.
 * If you change something here, add it to @padspin/db-functions
 */
export const dbNg = {
  notificationPreferences: (uid: string) =>
    DbService.docDataPointNg<NotificationPreferences>(
      `${notificationPreferencesName}/${uid}`
    ),
  viewing: (viewingId: string) =>
    DbService.docDataPointNg<Viewing>(`${viewingName}/${viewingId}`),
  viewings: () => DbService.colDataPointNg<Viewing>(viewingName),
  userNote: (uid: string) =>
    DbService.docDataPointNg<UserNote>(`${userNoteName}/${uid}`),
  recording: (id: string) =>
    DbService.docDataPointNg<RecordingsModel>(`${recordingsModelName}/${id}`),
  recordings: () =>
    DbService.colDataPointNg<RecordingsModel>(recordingsModelName),
  profile: (uid: string) =>
    DbService.docDataPointNg<UserProfile>(`${profile_model_name}/${uid}`),
  legacyListing: (listingId: string) =>
    DbService.docDataPointNg<ListingsModel>(`test_sell_jeremy/${listingId}`),
  legacyListingList: (uid: string, listingId: string) =>
    DbService.docDataPointNg<ListingsModel>(
      `test_sell_jeremylist/${uid}/items/${listingId}`
    ),
  articles: () => DbService.colDataPointNg<ArticleModel>(articleCollectionName),
  article: (uid: string) =>
    DbService.docDataPointNg<ArticleModel>(`${articleCollectionName}/${uid}`),
  filter: (uid: string) =>
    DbService.docDataPointNg<FilterModel>(`${filterName}/${uid}`),
  lead: (leadId: string) =>
    DbService.docDataPointNg<LeadsModel>(`${leadsModelName}/${leadId}`),
  leads: () => DbService.colDataPointNg<LeadsModel>(leadsModelName),
  leadNotifyQueue: (id: string) =>
    DbService.docDataPointNg<MessageAutomationModel>(
      `${messageAutomationName}/${id}`
    ),
  leadNotifyQueues: () =>
    DbService.colDataPointNg<MessageAutomationModel>(messageAutomationName),
  leadNotifyTemplate: (id: string) =>
    DbService.docDataPointNg<MessageTemplate>(`${message_template_name}/${id}`),
  leadNotifyTemplates: () =>
    DbService.colDataPointNg<MessageTemplate>(message_template_name),
  listingAdmin: (id: string) =>
    DbService.docDataPointNg<ListingsAdminModel>(`${listingsAdminName}/${id}`),
  conversation: (uid: string) =>
    DbService.docDataPointNg(`${conversation_model_name}/${uid}`),
  conversations: () => DbService.colDataPointNg(conversation_model_name),
  conversation_message: (uid: string, msgId: string) =>
    DbService.docDataPointNg(
      `${conversation_model_name}/${uid}/${conversation_messages_model_name}/${msgId}`
    ),
  conversation_messages: (uid: string) =>
    DbService.colDataPointNg(
      `${conversation_model_name}/${uid}/${conversation_messages_model_name}`
    ),
  tenantCriteria: (uid: string) =>
    DbService.docDataPointNg<TenantCriteriaModel>(
      `${tenantCriteriaName}/${uid}`
    ),
  messagesSMS: (phone: string) =>
    DbService.colDataPointNg<MessageSMSModel>(
      `${messageSMSName}/${phone}/messages/`
    ),
  messageSMS: (phone: string, timestamp: number) =>
    DbService.docDataPointNg<MessageSMSModel>(
      `${messageSMSName}/${phone}/messages/${timestamp}`
    ),
};

/**
 * A type-safe firestore implementation
 * {@link https://medium.com/swlh/using-firestore-with-typescript-65bd2a602945 with inspiration from Jamie Curnow }
 */
@Injectable({
  providedIn: 'root',
})
export class DbService {
  db = dbNg;
  static docDataPointNg = <T>(docPath: string) =>
    doc(getFirestore(), docPath).withConverter(
      DbService.converterNg()
    ) as unknown as DocumentReference<T>;
  static colDataPointNg = <T>(collectionPath: string) =>
    collection(getFirestore(), collectionPath).withConverter(
      DbService.converterNg()
    ) as unknown as CollectionReference<T>;
  static converterNg = <T>() => ({
    toFirestore: (data: Partial<T>) => data,
    fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
  });
}
