import type { Timestamp, FieldValue } from '@angular/fire/firestore';

export const articleCollectionName = 'articles' as const;

export type ArticleModel = {
  /** Name of author */
  author: string;
  /** @example "How to find the best rental in nyc" */
  title: string;
  /** Short description of the article */
  description: string;
  /** HTML content of article. This must be sanitized before being displayed. */
  content: string;
  /** When the blog post was created */
  created_at: Timestamp | FieldValue;
  /** When the blog post was last published */
  published_at: Timestamp | FieldValue | null;

  draft_author: string;
  draft_title: string;
  draft_description: string;
  draft_content: string;
};
