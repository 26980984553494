export const socialMediaAccountsCollectionName =
  'social-media-accounts' as const;

/**
 * Padspin's social media accounts that we maintain for different areas
 * (eg: @padspinnyc, @padspinsf, etc).
 */
export type SocialMediaAccountModel = {
  createdAt: Date;
  type: SupportedSocialMediaAccounts;
  handle: string;
  lat: number;
  lng: number;
};

export type SupportedSocialMediaAccounts = 'instagram';
