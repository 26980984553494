import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginOrRegisterModalComponent } from './login-or-register-modal/login-or-register-modal.component';
import { OnboardingModalComponent } from './onboarding-modal/onboarding-modal.component';
import { OnboardingInput } from './onboarding-modal/onboarding-input.type';
import { LoginOrRegisterInputType } from './login-or-register-modal/login-or-register-input.type';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { UserProfile, UserType } from '@padspin/models';
import {
  User,
  UserCredential,
  ActionCodeSettings,
  EmailAuthProvider,
  EmailAuthCredential,
  ParsedToken,
} from '@angular/fire/auth';
import {
  getAuth,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  linkWithCredential,
  Auth,
  onAuthStateChanged,
} from '@angular/fire/auth';
import { user, idToken, authState } from 'rxfire/auth';
import { serverTimestamp } from '@angular/fire/firestore';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeUserTypeRequestData,
  ChangeUserTypeResponse,
  changeUserTypeName,
  listUsersName,
  ListUsersResponse,
  ListUsersRequestData,
  DeleteUserRequestData,
  DeleteUserResponse,
  deleteUserName,
  createUserName,
  CreateUserRequestData,
  CreateUserResponse,
  getUserCustomClaimsName,
  GetUserCustomClaimsRequestData,
  GetUserCustomClaimsResponse,
} from '@padspin/function-types';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CustomClaims } from '@padspin/rbac';

export type UserClaims = ParsedToken &
  Record<'tenant' | 'landlord' | 'admin', boolean>;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$ = user(getAuth());
  idToken$ = idToken(getAuth());
  authState$ = authState(getAuth());
  private afa = new BehaviorSubject(getAuth());
  private currentUser = new ReplaySubject<User | undefined>(1);
  currentUser$ = this.currentUser.asObservable();
  currentUserUID$ = this.currentUser$.pipe(map((cu) => cu?.uid));
  private currentUserClaims: ReplaySubject<CustomClaims> =
    new ReplaySubject<CustomClaims>(1);
  currentUserClaims$ = this.currentUserClaims.asObservable();
  currentUserType$: Observable<UserType> = this.currentUserClaims$.pipe(
    map((claims) => (claims.tenant ? 'tenant' : 'landlord'))
  );
  isTenant$: Observable<boolean> = this.user$.pipe(
    map((u: User | null) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['tenant']) : false))
  );
  isLandlord$: Observable<boolean> = this.user$.pipe(
    map((u: User | null) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['landlord']) : false))
  );
  isAdmin$: Observable<boolean> = this.user$.pipe(
    map((u) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['admin']) : false))
  );
  isOwner$: Observable<boolean> = this.user$.pipe(
    map((u) => (u ? u.getIdTokenResult() : of(u))),
    switchMap((result) => result),
    map((r) => (r ? coerceBooleanProperty(r.claims['owner']) : false))
  );

  constructor(
    private readonly dialog: MatDialog,
    private readonly firestore: AngularFirestore,
    private readonly fireauth: Auth,
    private readonly functions: AngularFireFunctions
  ) {
    this.authState$.subscribe(() => this.afa.next(getAuth()));
    if (this.fireauth.currentUser) {
      this.currentUser.next(this.fireauth.currentUser);
    }
    onAuthStateChanged(this.fireauth, async (user: User | null) => {
      this.currentUser.next(user || undefined);
      if (user) {
        user
          .getIdTokenResult()
          .then((res) =>
            this.currentUserClaims.next(res.claims as CustomClaims)
          );
      } else {
        this.currentUserClaims.next({
          admin: false,
          landlord: false,
          tenant: false,
          account_manager: false,
          owner: false,
        });
      }
    });
  }

  async signOut(): Promise<void> {
    return getAuth().signOut();
  }

  async sendPasswordResetEmail(
    email: string,
    settings?: ActionCodeSettings | undefined
  ): Promise<void> {
    return sendPasswordResetEmail(getAuth(), email, settings);
  }

  /** @returns email */
  async verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordResetCode(getAuth(), code);
  }

  async confirmPasswordReset(code: string, password: string): Promise<void> {
    return confirmPasswordReset(getAuth(), code, password);
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return signInWithEmailAndPassword(getAuth(), email, password);
  }

  async createUserWithEmailAndPassword(data: {
    email: string;
    password: string;
    type: 'tenant' | 'landlord';
    first_name: string;
    last_name: string;
    phone: string;
  }): Promise<void> {
    const callable$ = this.functions.httpsCallable<
      CreateUserRequestData,
      CreateUserResponse
    >(createUserName);
    const result = await firstValueFrom(callable$(data));
    if (result.error) {
      throw result.error;
    }
    if (result.success) {
      // The first write to fire storage will fail without a delay
      await new Promise((r) => setTimeout(r, 200));
      return;
    }
  }

  async linkWithCredential(
    email: string,
    password: string
  ): Promise<UserCredential> {
    const cred: EmailAuthCredential = EmailAuthProvider.credential(
      email,
      password
    );
    const usr = getAuth().currentUser;
    if (!usr) {
      throw new Error('NOT LOGGED IN');
    }
    return linkWithCredential(usr, cred);
  }

  /**
   * If you cannot take an action before the user is logged in, subscribe to
   * this, and take your action after user becomes truthy.
   * @deprecated Replaced with this.authState$
   */
  onAuthStateChange(): Observable<User | null> {
    return authState(getAuth()).pipe(filter((u) => u !== null));
  }

  /** @deprecated Make reactive. */
  get user(): User | null {
    return this.afa.value.currentUser;
  }

  async showOnboardingDialog(
    data: OnboardingInput = { login: true }
  ): Promise<void> {
    const dialogRef = this.dialog.open(OnboardingModalComponent, {
      backdropClass: 'modal_backdrop',
      panelClass: 'modal_panel',
      hasBackdrop: true,
      disableClose: false,
      data,
    });
    await firstValueFrom(dialogRef.afterClosed());
  }

  async attemptLogin(
    data: LoginOrRegisterInputType = {}
  ): Promise<UserCredential | undefined> {
    const dialogRef = this.dialog.open<
      LoginOrRegisterModalComponent,
      LoginOrRegisterInputType
    >(LoginOrRegisterModalComponent, {
      data,
      backdropClass: 'modal_backdrop', // css class in styles.scss
      disableClose: true,
    });
    try {
      return firstValueFrom(dialogRef.afterClosed());
    } catch (error) {
      return;
    }
  }

  /** @deprecated */
  async saveUserInfoInDb(
    userId: string,
    email: string,
    first_name: string,
    last_name: string,
    phone: string,
    type: 'tenant' | 'landlord'
  ): Promise<AngularFirestoreDocument<UserProfile>> {
    const data = {
      email,
      phone,
      first_name,
      last_name,
      type,
      createdAt: serverTimestamp(),
    };
    const cell = type === 'landlord' ? 'sell' : 'buy';
    const col = this.firestore.collection<UserProfile>(cell).doc(userId);
    await col.set(data);
    return col;
  }

  async setUserType(options: {
    type: 'landlord' | 'tenant' | null;
    customClaims?: Partial<CustomClaims>;
    uid?: string;
  }): Promise<ChangeUserTypeResponse | undefined> {
    const currentUser = getAuth().currentUser;
    if (!currentUser || !currentUser.uid) {
      throw new Error('MUST_BE_LOGGED_IN');
    }
    const currentUserIdToken = await currentUser.getIdToken(true);
    const data: ChangeUserTypeRequestData = {
      uid: options.uid ?? currentUser.uid,
      type: options.type,
      admin:
        typeof options.customClaims?.admin !== 'undefined'
          ? options.customClaims?.admin
          : null,
      owner:
        typeof options.customClaims?.owner !== 'undefined'
          ? options.customClaims?.owner
          : null,
      account_manager:
        typeof options.customClaims?.account_manager !== 'undefined'
          ? options.customClaims?.account_manager
          : null,
      currentUserIdToken,
    };
    const callable$ = this.functions.httpsCallable<
      ChangeUserTypeRequestData,
      ChangeUserTypeResponse
    >(changeUserTypeName);
    return await firstValueFrom(callable$(data));
  }

  async getUserCustomClaims(uid: string): Promise<CustomClaims | null> {
    const callable$ = this.functions.httpsCallable<
      GetUserCustomClaimsRequestData,
      GetUserCustomClaimsResponse
    >(getUserCustomClaimsName);
    try {
      return firstValueFrom(callable$({ uid }));
    } catch (_error) {
      return null;
    }
  }

  async listUsers(
    config: { withClaim?: keyof CustomClaims } = {}
  ): Promise<ListUsersResponse | undefined> {
    const callable$ = this.functions.httpsCallable<
      ListUsersRequestData,
      ListUsersResponse
    >(listUsersName);
    const currentUser = getAuth().currentUser;
    if (!currentUser || !currentUser.uid) {
      throw new Error('MUST_BE_LOGGED_IN');
    }
    const currentUserIdToken = await currentUser.getIdToken(true);
    const listUserRequestData: ListUsersRequestData = {
      currentUserIdToken,
      customClaim: config.withClaim ?? undefined,
    };
    return await firstValueFrom(callable$(listUserRequestData));
  }

  async deleteUser(uid: string): Promise<void> {
    const callable$ = this.functions.httpsCallable<
      DeleteUserRequestData,
      DeleteUserResponse
    >(deleteUserName);
    const currentUser = getAuth().currentUser;
    if (!currentUser || !currentUser.uid) {
      throw new Error('MUST_BE_LOGGED_IN');
    }
    const currentUserIdToken = await currentUser.getIdToken(true);
    await firstValueFrom(callable$({ uid, currentUserIdToken }));
  }
}
