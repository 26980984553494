import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'splash',
    loadChildren: () =>
      import('../splash-page/splash-page.module').then(
        (m) => m.SplashPageModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('../tenant-landing-page/tenant-landing-page.module').then(
        (m) => m.TenantLandingPageModule
      ),
  },
  {
    path: 'tenant',
    loadChildren: () =>
      import('../tenant-landing-page/tenant-landing-page.module').then(
        (m) => m.TenantLandingPageModule
      ),
  },
  {
    path: 'landlord',
    loadChildren: () =>
      import('../landlord-landing-page/landlord-landing-page.module').then(
        (m) => m.LandlordLandingPageModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../register-page/register-page.module').then(
        (m) => m.RegisterPageModule
      ),
    data: { register: true },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../login-page/login-page.module').then((m) => m.LoginPageModule),
    data: { login: true },
  },
  {
    path: 'verify-phone',
    loadChildren: () =>
      import('../verify-phone/verify-phone.module').then(
        (m) => m.VerifyPhoneModule
      ),
  },
  {
    path: 'post-a-pad',
    loadChildren: () =>
      import('../post-a-pad-two/post-a-pad-two.module').then(
        (m) => m.PostAPadTwoModule
      ),
  },
  {
    path: 'criteria',
    loadChildren: () =>
      import('../tenant-criteria/tenant-criteria.module').then(
        (m) => m.TenantCriteriaModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('../blog-list-view-page/blog-list-view-page.module').then(
        (m) => m.BlogListViewPageModule
      ),
  },
  {
    path: 'blog/:blogPostId',
    loadChildren: () =>
      import('../blog-entry-view-page/blog-entry-view-page.module').then(
        (m) => m.BlogEntryViewPageModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'frequently-asked-questions',
    loadChildren: () => import('../faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('../faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('../terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServiceModule
      ),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('../terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServiceModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('../matches-page/matches-page.module').then(
        (m) => m.MatchesPageModule
      ),
  },
  {
    path: 'listings',
    loadChildren: () =>
      import('../matches-page/matches-page.module').then(
        (m) => m.MatchesPageModule
      ),
  },
  {
    path: 'l',
    loadChildren: () =>
      import('../matches-page/matches-page.module').then(
        (m) => m.MatchesPageModule
      ),
  },
  {
    path: 'no-fee-apartments-for-rent',
    loadChildren: () =>
      import('../matches-page/matches-page.module').then(
        (m) => m.MatchesPageModule
      ),
  },
  {
    path: 'matches',
    loadChildren: () =>
      import('../matches-page/matches-page.module').then(
        (m) => m.MatchesPageModule
      ),
  },
  {
    path: 'agreement',
    loadChildren: () =>
      import('../exclusive-form-page/exclusive-form-page.module').then(
        (m) => m.ExclusiveFormPageModule
      ),
  },
  {
    path: 'sign-agreement',
    loadChildren: () =>
      import('../exclusive-form-page/exclusive-form-page.module').then(
        (m) => m.ExclusiveFormPageModule
      ),
  },
  {
    path: 'forgot',
    data: { sendRequestEmail: true },
    loadChildren: () =>
      import('../forgot-page/forgot-page.module').then(
        (m) => m.ForgotPageModule
      ),
  },
  {
    path: '__/auth/action',
    loadChildren: () =>
      import('../email/email.module').then((m) => m.EmailModule),
  },
  {
    path: 'onboarding', // temporary
    loadChildren: () =>
      import('../onboarding-modal/onboarding-modal.module').then(
        (m) => m.OnboardingModalModule
      ),
  },
  {
    path: 'pitch-deck',
    loadChildren: () =>
      import('../pitch-deck-page/pitch-deck-page.module').then(
        (m) => m.PitchDeckPageModule
      ),
  },
  {
    path: 'rental-app',
    loadChildren: () =>
      import('../application-page/application-page.module').then(
        (m) => m.ApplicationPageModule
      ),
  },
  {
    path: 'leasebreak',
    loadChildren: () =>
      import('../leasebreak-landing-page/leasebreak-landing-page.module').then(
        (m) => m.LeasebreakLandingPageModule
      ),
  },
  {
    path: 'lease-break',
    redirectTo: 'leasebreak',
  },
  {
    path: 'move',
    loadChildren: () =>
      import('../moves-landing-page/moves-landing-page.module').then(
        (m) => m.MovesLandingPageModule
      ),
  },
  {
    path: 'move-form',
    loadChildren: () =>
      import('../move-form-page/move-form-page.module').then(
        (m) => m.MoveFormPageModule
      ),
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('../not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class FrontendRoutingModule {}
