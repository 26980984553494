import { Injectable } from '@angular/core';
import {
  RequireAtLeastOne,
  UserProfile,
  UserProfileID,
  profile_model_name,
} from '@padspin/models';
import { AuthService } from './auth.service';
import {
  where,
  documentId,
  getDoc,
  setDoc,
  collection,
  getDocs,
  getFirestore,
  orderBy,
  OrderByDirection,
  query,
  doc,
  QueryConstraint,
  Timestamp,
} from '@angular/fire/firestore';
import {
  getUserTypeName,
  GetUserTypeRequestData,
  GetUserTypeResponse,
  ListUsersResponse,
} from '@padspin/function-types';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { combineLatest, firstValueFrom, Observable, shareReplay } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { UuidService } from './uuid.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  maybeProfile$: Observable<UserProfileID | null> = combineLatest([
    this.authService.currentUserType$,
    this.authService.currentUserUID$,
  ]).pipe(
    switchMap(async ([type, maybeUID]) =>
      maybeUID
        ? type === 'landlord'
          ? this.getLandlordProfileByUID(maybeUID)
          : this.getTenantProfileByUID(maybeUID)
        : null
    )
  );

  currentProfile$: Observable<UserProfileID | null> = this.maybeProfile$.pipe(
    filter((maybeProfile): maybeProfile is UserProfileID => !!maybeProfile),
    shareReplay({ bufferSize: 1, refCount: false })
  );

  constructor(
    private readonly authService: AuthService,
    private readonly functions: AngularFireFunctions,
    private readonly uuidService: UuidService
  ) {}

  getAllTenants = async (
    orderByCol: keyof UserProfile = 'createdAt',
    orderByDir?: OrderByDirection
  ): Promise<UserProfileID[]> => this.getAll('tenant', orderByCol, orderByDir);

  getAllLandlords = async (
    orderByCol: keyof UserProfile = 'createdAt',
    orderByDir?: OrderByDirection
  ): Promise<UserProfileID[]> =>
    this.getAll('landlord', orderByCol, orderByDir);

  getAllAdmins = async (
    _orderByCol: keyof UserProfile = 'createdAt',
    _orderByDir?: OrderByDirection
  ): Promise<UserProfileID[]> => {
    let listUsersResponse: ListUsersResponse | undefined;
    try {
      listUsersResponse = await this.authService.listUsers({
        withClaim: 'admin',
      });
    } catch (_error) {
      console.warn(`Unable to fetch list of admin users`);
      listUsersResponse = undefined;
    }
    if (!listUsersResponse) {
      return [];
    }
    return listUsersResponse.users.map((userRecord) => ({
      id: userRecord.uid,
      phone: userRecord.phoneNumber || 'unknown',
      email: userRecord.email || 'unknown',
      createdAt: Timestamp.fromDate(new Date(userRecord.metadata.creationTime)),
      type: 'tenant',
      first_name: userRecord.displayName ?? '',
      last_name: '',
    }));
  };

  /** @deprecated */
  getTenantProfileByUID = async (uid: string): Promise<UserProfileID | null> =>
    this.getProfileByUID(uid);

  /** @deprecated */
  getLandlordProfileByUID = async (
    uid: string
  ): Promise<UserProfileID | null> => this.getProfileByUID(uid);

  getProfile = async (
    phoneEmailOrUID?: string,
    firestore = getFirestore()
  ): Promise<UserProfileID | null> => {
    let _phoneEmailOrUID: string | undefined;
    if (typeof phoneEmailOrUID === 'undefined') {
      _phoneEmailOrUID = await firstValueFrom(this.authService.currentUserUID$);
      if (!_phoneEmailOrUID) {
        throw new Error('Must be logged in to get user profile');
      }
    } else {
      _phoneEmailOrUID = phoneEmailOrUID;
    }
    const snaps = await getDocs(
      query(
        collection(firestore, profile_model_name),
        this.#getWhereConstraint(_phoneEmailOrUID)
      )
    );
    if (snaps.empty) {
      // Profile not found. Create one now.
      throw new Error(`No user exists with identifier: ${phoneEmailOrUID}`);
    }
    const snap = snaps.docs[0];
    if (!snap) {
      return null;
    }
    return { ...(snap.data() as UserProfile), id: snap.id };
  };

  getProfileByUID = async (
    userId?: string,
    firestore = getFirestore()
  ): Promise<UserProfileID | null> => {
    let uid: string | undefined;
    if (!userId) {
      uid = await firstValueFrom(this.authService.currentUserUID$);
      if (!uid) {
        throw new Error('Failed to get uid');
      }
    } else {
      uid = userId;
    }
    const snap = await getDoc(doc(firestore, profile_model_name, uid));
    if (snap.exists()) {
      return { id: snap.id, ...(snap.data() as UserProfile) };
    }
    // Create a new profile
    const callable$ = this.functions.httpsCallable<
      GetUserTypeRequestData,
      GetUserTypeResponse
    >(getUserTypeName);
    await firstValueFrom(callable$({ uid })).then((response) => response?.type);
    throw new Error(`No profile exists with uid: ${userId}`);
  };

  getBy = async (
    by:
      | string
      | RequireAtLeastOne<Pick<UserProfileID, 'email' | 'phone' | 'id'>>
  ): Promise<UserProfileID | null> => {
    const matrix = {
      uid: (uid: string) => this.getProfileByUID(uid),
      email: (email: string) => this.getByEmail(email),
      phone: (phone: string) => this.getByPhone(phone),
    };
    if (typeof by === 'string') {
      const t = this.#isUidPhoneOrEmail(by);
      return matrix[t](by);
    } else if (by.id) {
      return matrix['uid'](by.id);
    } else if (by.email) {
      return matrix['email'](by.email);
    } else if (by.phone) {
      return matrix['phone'](by.phone);
    }
    console.warn('Must include id, email, or phone');
    return null;
  };

  #isUidPhoneOrEmail = (id: string): 'uid' | 'phone' | 'email' =>
    isPossiblePhoneNumber(id, 'US')
      ? 'phone'
      : /.*@.*/.test(id)
      ? 'email'
      : 'uid';

  /**
   * Get the user's type from firebase-admin auth.
   * @deprecated
   */
  getTypeByUID = async (
    uid: string
  ): Promise<Record<'tenant' | 'landlord' | 'admin', boolean>> => {
    const callable$ = this.functions.httpsCallable<
      GetUserTypeRequestData,
      GetUserTypeResponse
    >(getUserTypeName);
    const type = await firstValueFrom(callable$({ uid }));
    if (!type) {
      throw new Error('USER_HAS_NO_TYPE');
    }
    return {
      tenant: type.type === 'tenant',
      landlord: type.type === 'landlord',
      admin: type.admin,
    };
  };

  getByEmail = async (
    email: string,
    firestore = getFirestore()
  ): Promise<UserProfileID | null> => {
    const querySnaps = await getDocs(
      query(
        collection(firestore, profile_model_name),
        where('email', '==', email)
      )
    );
    if (!querySnaps.empty) {
      const snap = querySnaps.docs[0];
      return { ...(snap.data() as UserProfile), id: snap.id };
    }
    throw new Error(`No profile exists with email: ${email}`);
  };

  getByPhone = async (
    phone: string,
    firestore = getFirestore()
  ): Promise<UserProfileID | null> => {
    const snaps = await getDocs(
      query(
        collection(firestore, profile_model_name),
        where('phone', '==', phone)
      )
    );
    if (!snaps.empty) {
      return { ...(snaps.docs[0].data() as UserProfile), id: snaps.docs[0].id };
    }
    throw new Error(`No profile exists with phone number: ${phone}`);
  };

  private getAll = async (
    type: 'tenant' | 'landlord' = 'tenant',
    orderByCol: keyof UserProfile = 'createdAt',
    orderByDir?: OrderByDirection,
    firestore = getFirestore()
  ): Promise<UserProfileID[]> => {
    const q = query(
      collection(firestore, profile_model_name),
      where('type', '==', type),
      orderBy(orderByCol, orderByDir)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as UserProfileID)
    );
  };

  updateProfileByID = async (
    uid: string,
    profile: Partial<UserProfile>,
    firestore = getFirestore()
  ) => {
    const profileRef = doc(firestore, profile_model_name, uid);
    await setDoc(profileRef, profile, { merge: true });
  };

  #getWhereConstraint = (phoneEmailOrUID: string): QueryConstraint =>
    this.getIdentifierType(phoneEmailOrUID) === 'phone'
      ? where('phone', '==', phoneEmailOrUID)
      : this.getIdentifierType(phoneEmailOrUID) === 'email'
      ? where('email', '==', phoneEmailOrUID)
      : where(documentId(), '==', phoneEmailOrUID);

  getIdentifierType = (phoneEmailOrUID: string): 'phone' | 'email' | 'uid' =>
    this.uuidService.isPhone(phoneEmailOrUID)
      ? 'phone'
      : this.uuidService.isEmail(phoneEmailOrUID)
      ? 'email'
      : 'uid';
}
