import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { PadBookShowingComponent } from './search-page/pad-book-showing/pad-book-showing.component';
import { firstValueFrom, from, Observable, of, OperatorFunction } from 'rxjs';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '@angular/fire/auth';
import {
  collection,
  FieldValue,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  where,
  orderBy,
  limit,
  Timestamp,
  addDoc,
} from '@angular/fire/firestore';
import { collection as collection$ } from 'rxfire/firestore';
import { PadBookShowingOutput } from './search-page/pad-book-showing/pad-book-showing-output.interface';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';
import { ProfileService } from './profile.service';
import { LoadingService } from './loading.service';
import {
  ListingsModel,
  ListingsModelID,
  UserProfileID,
  Viewing,
  viewingName,
} from '@padspin/models';
import { Listings2Service } from './listings-2.service';
import { TenantCriteriaService } from './tenant-criteria.service';
import { PadBookShowingInput } from './search-page/pad-book-showing/pad-book-showing-input.type';
import { MessageAutomationService } from './message-automation.service';

/** @deprecated */
export interface LegacyViewing {
  listingId: string;
  listing_slug?: string;
  annual_income: string;
  credit_score: string;
  earliest_move_in_date?: string;
  funds_available: string;
  guarantor: string;
  other_day: string;
  today: string;
  tomorrow: string;
  has_vaccine?: boolean;
  prefer_in_person?: boolean;
  prefer_virtual?: boolean;
  createdAt?: FieldValue | Date;
  updatedAt?: FieldValue | Date;
}

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private isMobile$: Observable<BreakpointState> =
    this.breakpointObserver.observe(Breakpoints.XSmall);

  readonly viewings$ = this.authService.authState$.pipe(
    filter((user: User | null) => !!user && !!user?.uid),
    map((user: User | null) => user as User),
    switchMap((user: User) =>
      this.firestore
        .collection('viewing')
        .doc(user?.uid || '')
        .collection('items')
        .valueChanges()
    ),
    map((documentDataArray) => documentDataArray as LegacyViewing[])
  );

  readonly viewingsAndPads$: Observable<
    Array<{ viewing: LegacyViewing; pad?: ListingsModel }>
  > = this.viewings$.pipe(
    mergeMap(async (viewings: LegacyViewing[]) => {
      const result = [];
      for (const viewing of viewings) {
        const pad = await firstValueFrom(
          this.listingsService.getListingByID$(viewing.listingId)
        );
        result.push({ viewing, pad });
      }
      return result;
    })
  );

  constructor(
    private readonly dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly authService: AuthService,
    private readonly firestore: AngularFirestore,
    private readonly remoteConfig: AngularFireRemoteConfig,
    private readonly matSnackBar: MatSnackBar,
    private readonly listingsService: Listings2Service,
    private readonly analytics: GoogleAnalyticsService,
    private readonly profileService: ProfileService,
    private readonly loadingService: LoadingService,
    private readonly criteriaService: TenantCriteriaService,
    private readonly messageAutomationService: MessageAutomationService
  ) {}

  /**
   * @param listing the user is trying to schedule a viewing with.
   */
  async bookShowing(listing: ListingsModelID): Promise<boolean> {
    this.analytics.log('open_pad_booking_dialog');
    const bookingFormData: PadBookShowingOutput | undefined =
      await this.showBookingDialog(listing);
    if (!bookingFormData) {
      this.analytics.log('close_pad_booking_dialog_form_incomplete');
      return false;
    }
    this.loadingService.show();
    let currentUser: User | undefined;
    try {
      currentUser = await firstValueFrom(
        this.authService.currentUser$.pipe(first())
      );
    } catch (e) {
      this.matSnackBar.open('Failed to get current user', 'OK');
      this.loadingService.hide();
    }
    if (currentUser && currentUser.isAnonymous) {
      try {
        await this.authService.signOut();
      } catch (e) {
        this.matSnackBar.open('Failed to get anonymous user', 'OK');
        this.loadingService.hide();
      }
      currentUser = undefined;
    }
    if (!currentUser) {
      this.loadingService.hide();
      console.log('Waiting for user to login or register');
      try {
        await this.showLoginOrRegisterDialog();
        this.analytics.log('book_showing_signup_completed');
      } catch (e) {
        this.matSnackBar.open('Failed to register or login', 'OK');
        this.loadingService.hide();
      }
      this.loadingService.show();
      try {
        currentUser = await firstValueFrom(this.authService.currentUser$);
      } catch (e) {
        this.loadingService.hide();
      }
    }
    if (!bookingFormData) {
      this.matSnackBar.open('Failed to load booking', 'OK');
    }
    if (!currentUser) {
      this.matSnackBar.open('Failed to load user', 'OK');
    }
    if (!bookingFormData || !currentUser) {
      this.analytics.log('close_pad_booking_dialog_form_incomplete');
      console.log('incomplete form');
      this.loadingService.hide();
      return false;
    }
    const profile: UserProfileID | null =
      await this.profileService.getProfileByUID();
    if (!profile) {
      console.log(
        'Account created successfully, but unable to retrieve user profile from database'
      );
      this.loadingService.hide();
      this.matSnackBar.open('Failed to get profile', 'OK');
      return false;
    }
    const viewing: Viewing = {
      user_id: profile.id,
      annual_income: Number(bookingFormData.annual_income),
      createdAt: serverTimestamp(),
      credit_score: Number(bookingFormData.credit_score),
      earliest_move_in_date: bookingFormData.earliest_move_in_date,
      funds_available: Number(bookingFormData.funds_available),
      guarantor: bookingFormData.guarantor,
      has_vaccine: bookingFormData.has_vaccine,
      listing_slug: listing.slug,
      listingId: listing.id,
      phone: profile.phone,
      prefers: bookingFormData.prefers,
      today: bookingFormData.today,
      today_start: Timestamp.fromDate(bookingFormData.today_start),
      today_end: Timestamp.fromDate(bookingFormData.today_end),
      tomorrow: bookingFormData.tomorrow,
      tomorrow_start: Timestamp.fromDate(bookingFormData.tomorrow_start),
      tomorrow_end: Timestamp.fromDate(bookingFormData.tomorrow_end),
      day_after_tomorrow: bookingFormData.day_after_tomorrow,
      day_after_tomorrow_start: Timestamp.fromDate(
        bookingFormData.day_after_tomorrow_start
      ),
      day_after_tomorrow_end: Timestamp.fromDate(
        bookingFormData.day_after_tomorrow_end
      ),
      updatedAt: serverTimestamp(),
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      type: profile.type,
      plan_to_hire_movers: bookingFormData.plan_to_hire_movers,
    };

    try {
      await this.scheduleViewing(viewing);
    } catch (e) {
      console.log(e);
      this.matSnackBar.open('Failed to schedule a viewing', 'OK');
      this.loadingService.hide();
    }

    try {
      await this.criteriaService.createCriteriaIfNecessary(viewing, listing);
    } catch (error) {
      this.matSnackBar.open('Unable to create criteria', 'OK', {
        duration: 3000,
      });
    }

    this.analytics.log('close_pad_booking_dialog_booking_complete', viewing);
    this.loadingService.hide();
    return true;
  }

  async showLoginOrRegisterDialog(
    options: { email?: string } = {}
  ): Promise<void> {
    const isAnonymous = this.authService.user?.isAnonymous || false;
    const isLoggedIn = !!this.authService.user;
    if (!isLoggedIn || isAnonymous) {
      await this.authService
        .attemptLogin({ ...options, type: 'tenant' })
        .catch(() => {
          return undefined;
        });
    }
  }

  async showBookingDialog(
    listing: ListingsModelID
  ): Promise<PadBookShowingOutput | undefined> {
    const d: MatDialogRef<PadBookShowingComponent, PadBookShowingOutput> =
      this.dialog.open<PadBookShowingComponent, PadBookShowingInput>(
        PadBookShowingComponent,
        {
          backdropClass: 'modal_backdrop', // css class in styles.scss
          panelClass: 'modal_panel',
          width: 'calc(100% - 50px)',
          maxWidth: '687px',
          height: '85vh',
          maxHeight: '85vh',
          data: { listing },
          autoFocus: false,
        }
      );
    this.setDialogSize(d);
    return await firstValueFrom(
      d.afterClosed().pipe(tap((booking) => console.log({ booking })))
    );
  }

  setDialogSize(d: MatDialogRef<PadBookShowingComponent>): void {
    // Set the size of the dialog
    const smallDialogSubscription = this.isMobile$.subscribe((size) => {
      if (size.matches) {
        d.updateSize('100vw', '100vh');
      } else {
        d.updateSize('calc(100% - 50px)', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
    });
  }

  async scheduleViewing(
    viewing: Omit<Viewing, 'createdAt' | 'updatedAt'>,
    firestore = getFirestore()
  ): Promise<void> {
    const u = await firstValueFrom(this.authService.currentUser$.pipe(first()));
    if (!u || !u.uid) {
      console.warn('You must be logged in to schedule a viewing.');
      return;
    }
    try {
      await addDoc(collection(firestore, viewingName), {
        ...viewing,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.warn(error);
      return;
    }
    // Now that we have scheduled a viewing, the lead has soft-converted to
    // a booking. We should remove them from the lead queue so they don't get
    // annoyed by more SMS messages after already scheduling a viewing.
    try {
      await this.messageAutomationService.dequeueLeadByPhone(viewing.phone);
    } catch (error) {
      console.warn(
        'Unable to prevent more SMS messages from being sent to the lead'
      );
      return;
    }
    this.matSnackBar.open(`Created booking for ${viewing.first_name}.`, 'OK', {
      duration: 5000,
    });
  }

  getBookingChangesBy$ = (
    uidOrPhone: string,
    firestore = getFirestore()
  ): Observable<Viewing[]> =>
    from(this.profileService.getBy(uidOrPhone)).pipe(
      filter((maybeProfile) => !!maybeProfile) as OperatorFunction<
        UserProfileID | null,
        UserProfileID
      >,
      switchMap((profile) =>
        collection$(
          query(
            collection(firestore, viewingName),
            where('user_id', '==', profile.id),
            orderBy('createdAt', 'asc')
          )
        )
      ),
      map((snaps) => snaps.map((snap) => ({ ...(snap.data() as Viewing) }))),
      catchError((e: unknown) => {
        if (Object(e).message.includes('No profile exists with phone number')) {
          return of([]);
        }
        console.log(`Error retrieving viewings for ${uidOrPhone}. Error:`, e);
        return of([]);
      })
    );

  getBookingsBy$ = (
    uidOrPhone: string,
    firestore = getFirestore()
  ): Observable<Viewing[]> =>
    from(this.profileService.getByPhone(uidOrPhone)).pipe(
      filter((maybeProfile) => !!maybeProfile) as OperatorFunction<
        UserProfileID | null,
        UserProfileID
      >,
      switchMap(async (profile) =>
        getDocs(
          query(
            collection(firestore, viewingName),
            where('user_id', '==', profile.id)
          )
        )
      ),
      map((snaps) =>
        snaps.docs.map((snap) => ({ ...(snap.data() as Viewing) }))
      ),
      catchError(() => of([]))
    );

  getBookings$ = (
    limitTo: number = 500,
    firestore = getFirestore()
  ): Observable<Viewing[]> =>
    collection$(
      query(
        collection(firestore, viewingName),
        orderBy('createdAt', 'desc'),
        limit(limitTo)
      )
    ).pipe(
      map((snaps) =>
        snaps.map((snap) => ({ id: snap.id, ...(snap.data() as Viewing) }))
      ),
      map((viewings) =>
        viewings.map((viewing) => {
          if (
            !viewing.createdAt ||
            !(viewing.createdAt as Timestamp).toDate ||
            isNaN((viewing.createdAt as Timestamp).toDate().getTime())
          ) {
            return { ...viewing, createdAt: Timestamp.fromDate(new Date()) };
          }
          return viewing;
        })
      )
    );

  getBookingsByListingSlug = async (slug: string): Promise<Viewing[]> => {
    const snaps = await getDocs(
      query(
        collection(getFirestore(), viewingName),
        where('listing_slug', '==', slug)
      )
    );
    return snaps.docs.map((snap) => ({ ...(snap.data() as Viewing) }));
  };
}
