import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'redactText',
})
export class RedactTextPipe implements PipeTransform {
  transform(value: unknown, ..._args: unknown[]): string | null {
    if (typeof value !== 'string') {
      return null;
    }
    return value
      .split('')
      .map(() => '•')
      .join('');
  }
}
