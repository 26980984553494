import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { ReplaySubject, Subject, tap } from 'rxjs';
import { isListingContact, ListingContact } from '@padspin/models';
import { phoneValidator2 } from '../validators/validators';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'padspin-listing-edit-contact',
  templateUrl: './listing-edit-contact.component.html',
  styleUrls: ['./listing-edit-contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListingEditContactComponent),
      multi: true,
    },
  ],
})
export class ListingEditContactComponent
  implements ControlValueAccessor, OnDestroy
{
  #destroy$ = new Subject<void>();

  contactForm = this.fb.group(
    {
      name: [null, Validators.required],
      email: [
        null,
        Validators.compose([Validators.email, Validators.required]),
      ],
      phone: [null, Validators.compose([phoneValidator2, Validators.required])],
      type: [null, Validators.required],
    },
    { updateOn: 'change' }
  );

  @Input() set type(
    value: 'owner' | 'property_manager' | 'lease_owner' | 'supervisor'
  ) {
    this.contactForm.get('type')?.setValue(value);
    const t =
      value === 'property_manager'
        ? 'Property Manager'
        : value === 'lease_owner'
        ? 'Lease Owner'
        : value === 'supervisor'
        ? 'Supervisor'
        : 'Owner';
    this.type$.next(t);
  }

  type$ = new ReplaySubject<
    'Owner' | 'Property Manager' | 'Lease Owner' | 'Supervisor'
  >(1);

  private changesSub = this.contactForm.valueChanges
    .pipe(
      debounceTime(1000),
      map(() => this.getListingContact()),
      tap((maybeContact) => this.propagateOnChange(maybeContact)),
      takeUntil(this.#destroy$)
    )
    .subscribe();

  propagateOnChange: (
    value: Parameters<ControlValueAccessor['registerOnChange']>[0]
  ) => void = (_: Parameters<ControlValueAccessor['registerOnChange']>[0]) => {
    /* NOOP */
  };

  propagateOnTouched: (
    value: Parameters<ControlValueAccessor['registerOnTouched']>[0]
  ) => void = (_: Parameters<ControlValueAccessor['registerOnTouched']>[0]) => {
    /* NOOP */
  };

  constructor(private readonly fb: FormBuilder) {}

  private getListingContact(): ListingContact | null {
    const contact: ListingContact = {
      email: this.contactForm.get('email')?.value || null,
      phone: this.contactForm.get('phone')?.value || null,
      name: this.contactForm.get('name')?.value || null,
      type: this.contactForm.get('type')?.value || null,
    };
    return isListingContact(contact) ? contact : null;
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  registerOnChange(
    fn: Parameters<ControlValueAccessor['registerOnChange']>[0]
  ): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(
    fn: Parameters<ControlValueAccessor['registerOnTouched']>[0]
  ): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: ListingContact): void {
    if (value && isListingContact(value)) {
      this.contactForm.setValue(value);
    }
  }
}
